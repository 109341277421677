.gradient-custom-4 {
  background: #84fab0;
  background: linear-gradient(
    to right,
    rgba(132, 250, 176, 1),
    rgba(143, 211, 244, 1)
  );
}

.hidden {
  display: none;
}

.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}

.checkbox-wrapper-1 [type="checkbox"].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline-flex;
  margin-right: 30px;
  margin-bottom: 10px;
  appearance: none;
}

.checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset,
    0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
      no-repeat center,
    white;
  background-size: 0;
  transition: color 0.1s, border 0.1s, box-shadow 0.1s;
}

.checkbox-wrapper-1 [type="checkbox"].substituted:enabled:active + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:enabled
  + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}

.checkbox-wrapper-1 [type="checkbox"].substituted:checked + label:before {
  background-color: #3b99fc;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}

.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:before {
  box-shadow: none; /* Disable focus shadow */
}

.checkbox-wrapper-1 [type="checkbox"].substituted:focus {
  outline: none; /* Remove focus outline */
}

.checkbox-wrapper-1 [type="checkbox"].substituted:disabled + label:before {
  opacity: 0.5;
}

:root {
  --primary-color: #1a237e;
  --dark-primary-color: #0f1041;
  --accent-color: #2962ff;
  --sub-yellow: #ffca00;
}

html,
body {
  height: 100%;
  background: #fff;
  font-family: sans-serif;
  font-size: 14px;
}

#wrap {
  width: 100%;
  max-width: 900px;
  margin: 0 auto 60px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.input::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: var(--dark-primary-color);
}

.input-header {
  position: relative;
  margin-left: 6px;
  padding-top: 80px;
  color: #fff;
}

.input-header h1 {
  padding-bottom: 25px;
  font-size: 3.25em;
  font-weight: 100;
}

.input-content {
  position: relative;
  padding: 44px 55px;
  background: #fff;
  z-index: 10;
}

.input-content h2 {
  padding-bottom: 45px;
  font-size: 1.625em;
  font-weight: bold;
  vertical-align: middle;
}

.input-content h2 span {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 6px 3px;
  border: 1px solid var(--sub-yellow);
  border-radius: 4px;
  font-size: 0.85rem;
  vertical-align: middle;
  color: var(--sub-yellow);
}

.inputbox {
  overflow: hidden;
  position: relative;
  padding: 15px 0 28px 200px;
}

.inputbox-title {
  position: absolute;
  top: 15px;
  left: 0;
  width: 200px;
  height: 30px;
  color: #666;
  font-weight: bold;
  line-height: 30px;
}

.inputbox-content {
  position: relative;
  width: 100%;
}

.inputbox-content input {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  font-size: 16px;
  border: 0;
  background: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  border-radius: 0;
}

.inputbox-content input:focus,
.inputbox-content input:valid {
  & ~ label {
    color: var(--accent-color);
    transform: translateY(-20px);
    font-size: 0.825em;
    cursor: default;
  }
}

.inputbox-content input:focus {
  & ~ .underline {
    width: 100%;
  }
}

.inputbox-content label {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  line-height: 30px;
  color: #ccc;
  cursor: text;
  transition: all 200ms ease-out;
  z-index: 10;
}

.inputbox-content .underlinea {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--accent-color);
  transition: all 200ms ease-out;
}

.btns {
  padding: 30px 0 0 200px;
}

.btns .btn {
  display: inline-block;
  margin-right: 2px;
  padding: 10px 25px;
  background: none;
  border: 1px solid #c0c0c0;
  border-radius: 2px;
  color: #666;
  font-size: 1.125em;
  outline: none;
  transition: all 100ms ease-out;
}

.btns .btn:hover,
.btns .btn:focus {
  transform: translateY(-3px);
}

.btns .btn-confirm {
  border: 1px solid var(--accent-color);
  background: var(--accent-color);
  color: #fff;
}

.inputbox .inputbox-content .input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.inputbox .inputbox-content .input-field {
  padding-right: 60px;
}

.inputbox .inputbox-content .character-count {
  position: absolute;
  right: 10px;
  font-size: 0.9em;
  color: #666;
}

@media (max-width: 600px) {
  .gradient-custom-4 {
    background-size: cover;
  }

  .checkbox-wrapper-1 [type="checkbox"].substituted {
    margin-right: 15px;
    margin-bottom: 5px;
  }

  .checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
  }

  .checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:before {
    box-shadow: none;
  }

  .checkbox-wrapper-1 [type="checkbox"].substituted:focus {
    outline: none;
  }

  .input-header {
    padding-top: 40px;
  }

  .input-header h1 {
    font-size: 2em;
  }

  .input-content {
    padding: 20px;
  }

  .input-content h2 {
    padding-bottom: 20px;
    font-size: 1.2em;
  }

  .input-content h2 span {
    font-size: 0.75rem;
  }

  .inputbox {
    padding: 10px 0 20px 100px;
  }

  .inputbox-title {
    width: 100px;
  }

  .inputbox-content input {
    height: 25px;
    font-size: 16px;
  }

  .btns {
    padding: 20px 0 0 100px;
  }

  .btns .btn {
    padding: 8px 20px;
    font-size: 1em;
  }
}

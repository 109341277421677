.flex-end-bold {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 16px;
  }
  
  .title {
    font-family: Arial, sans-serif;
    font-size: 2.5em;
    color: #228B22;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-left: 2%;
    animation: fadeInUp 0.5s ease-in-out;
  }
  
  .image-style {
    max-width: 60%;
    margin-bottom: 20px;
  }
  
  .grid-details {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
  }
  
  .typography-body1 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: #800000;
    margin: 0;
  }

  @media (max-width: 600px) {
    .flex-end-bold {
      font-size: 14px;
    }
  
    .title {
      font-size: 1.5em;
      margin-left: 1%;
    }
  
    .image-style {
      max-width: 80%;
      margin-bottom: 15px;
    }
  
    .grid-details {
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }
  
    .typography-body1 {
      font-size: 16px;
      line-height: 1.4;
    }
  }
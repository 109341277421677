.slide-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .each-fade {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .fade-image {
    width: 100%;
    height: auto;
    max-height: 1000px;
    object-fit: cover;
  }
  
  h2 {
    margin: 10px 0;
  }
  
  .slide-container + * {
    margin-top: 20px;
  }
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.header {
  background-color: #f1f1f1;
  padding: 20px;
}

.header h1 {
  text-align: center;
  margin-bottom: 0;
  font-size: 20px;
}

.header p {
  text-align: center;
  background-color: #fff;
}

.header .logo {
  text-align: center;
}

.header .logo img {
  cursor: pointer;
  width: 100px;
}

.menuItemCustom {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 4px; 
  position: relative;
}

.menuItemCustom .balance {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.menuItemCustom .balance span {
  font-size: medium;
  color: white;
}

.circle-dropdown {
  position: relative;
  display: inline-block;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #999999;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.circle-dropdown-content {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: black;
  background-color: rgb(255, 255, 255);
  min-width: 130px;
  border-radius: 5%;
  margin-top: 8px;
  border: 1px solid #e0e0e0;
  position: absolute;
  z-index: 1200;
}

.circle-dropdown-item {
  padding: 12px 16px;
  border-radius: 8%;
  transition: background-color 0.3s;
}

.circle-dropdown-item:hover {
  background-color: #f0f0f0;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background-color: #000;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menuItem {
  display: inline-block;
  text-align: center;
  margin: 0 30px;
}

.menu .menuItem a {
  font-family: "Anton", sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  color: #fff;
  text-decoration: none;
}

.menu .menuItem .balance {
  display: flex;
}

.menu .menuItem .balance span {
  font-family: "Anton", sans-serif;
  font-size: medium;
  letter-spacing: 1px;
  color: #fff;
  text-decoration: none;
}

.menu .userIcon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.logo {
  height: 50px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .logo {
    height: 35px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .logo {
    height: 30px;
    margin-right: 10px;
  }
}

/* Mobile view styles */
@media (max-width: 768px) {
  .menu .menuItem {
    margin: 0 10px;
  }

  .menu .menuItem a {
    font-size: small;
  }

  .menu .balance {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu .menuItem .balance span {
    font-size: small;
  }
}

.dropbtn {
  font-family: "Anton", sans-serif;
  margin-left: 50px;
  background-color: #000;
  color: white;
  padding: 12px;
  font-size: 15px;
  letter-spacing: 1px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  border-radius: 8px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-content a {
  color: black;
  font-family:'Times New Roman', Times, serif;
  font-size: 15px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
  border-radius: 8px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #130432;
  border-radius: 12px;
}

@media (max-width: 480px) {
  .dropbtn {
    padding: 10px;
    font-size: 14px;
    margin-left: 10px;
  }

  .dropdown-content {
    min-width: 130px;
    margin-top: 4px;
  }

  .dropdown-content a {
    padding: 4px 8px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.page {
  padding: 0px;
  background-color: #fff;
}

.page h2 {
  text-align: center;
}

.page p {
  text-align: center;
}

.inputs .input {
  text-align: center;
}

.inputs .input input {
  height: 30px;
  width: 300px;
  margin: 5px 0;
}

.inputs .button {
  text-align: center;
}

.inputs .button button {
  background-color: #555;
  border: none;
  padding: 15px 30px;
  color: #fff;
  width: 308px;
  margin: 10px 0;
}

.error {
  background-color: red;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 8px;
  color: #fff;
}



.professorText {
  margin-top: 20px;
  color: #2196f3;
  animation: fadeIn 1s ease-in-out;
  font-weight: bold;
  text-decoration: underline;
  padding-left: 60%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.eleveText {
  margin-top: 20px;
  color: #800000;
  animation: fadeIn 1s ease-in-out;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding-left: 60%;
}

.textCardVisible {
  height: auto;
}

.textCardHidden {
  height: 0;
}

.not-found-message {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 12%;
}

.not-found-message p {
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 28px;
  font-style: italic;
  font-weight: 500;
  color: #1d1f7f;
  text-align: center;
  animation: fadeIn 1s ease;
}

.votes {
  color: grey;
  font-size: small;
  margin-top: 4px;
  margin-left: 4px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.type-animation {
  font-family: Arial, sans-serif;
  color: #4caf50;
  text-shadow: 2px 2px 4px #000000;
  margin-left: 25%;
  margin-top: 10px;
  font-size: 2.5em;
}

@media (max-width: 1200px) {
  .type-animation {
    font-size: 2.5em;
    margin-left: 20%;
  }
}

@media (max-width: 992px) {
  .type-animation {
    font-size: 2.5em;
    margin-left: 15%;
  }
}

@media (max-width: 768px) {
  .type-animation {
    font-size: 2em;
    margin-left: 10%;
  }
}

@media (max-width: 576px) {
  .type-animation {
    font-size: 1.75em;
    margin-left: 8%;
  }
}

.policy-title {
  color: blue;
  font-family: Georgia, serif;
  font-size: 20px;
  text-align: center;
  padding-left: 30%;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .policy-title {
    font-size: 16px;
    padding-left: 10%;
  }
}

.home-container {
  text-align: center;
  padding: 40px 20px;
  background-color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.swiper {
  margin-top: 10px;
}

.review-box {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 10px;
}

.review-text {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif;
}

.review-author h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #555;
  margin: 0;
}

.review-author span {
  font-size: 0.9rem;
  color: #999;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .home-container {
    padding: 20px 10px;
  }

  .review-box {
    padding: 20px;
    max-width: 90%;
  }

  .review-text {
    font-size: 1rem;
  }

  .review-author h3 {
    font-size: 1rem;
  }

  .review-author span {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .review-box {
    padding: 15px;
  }

  .review-text {
    font-size: 0.9rem;
  }

  .review-author h3 {
    font-size: 0.9rem;
  }

  .review-author span {
    font-size: 0.8rem;
  }
}

.review-title {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin: 20px 0;
  font-family: 'Montserrat', sans-serif;
}

.review-count {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px; /* Space between cards */
  padding: 16px;
}

.card {
  flex: 1 1 calc(25% - 16px); /* Four cards in one row (25% width minus gap) */
  max-width: calc(25% - 16px);
  box-sizing: border-box;
}

/* For phone view: Two cards per row */
@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 16px); /* Two cards in one row (50% width minus gap) */
    max-width: calc(50% - 16px);
  }
}

/* For very small devices: One card per row */
@media (max-width: 480px) {
  .card {
    flex: 1 1 100%; /* Full width for each card */
    max-width: 100%;
  }
}
.home-section-title {
  text-align: center;
  margin-bottom: 40px;
}

.home-main-title {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  position: relative;
}

.home-underline {
  width: 6em;
  height: 4px;
  background-color: #4caf50;
  margin: 10px auto 0;
  position: relative;
  animation: moveUnderline 3s infinite;
}

@keyframes home-moveUnderline {
  0% {
    left: -3em;
  }
  50% {
    left: 3em;
  }
  100% {
    left: -3em;
  }
}

.home-underlineb {
  width: 4em;
  height: 2px;
  background-color: #002f75;
  margin: 12px;
  margin-bottom: 20px;
  position: relative;
}

.home-b-advantages {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: transform 0.3s ease-in-out;
}

.home-b-advantages__icon {
  font-size: 40px;
  margin-right: 15px;
  color: #4caf50;
}

.home-b-advantages__inner {
  flex-grow: 1;
}

.home-b-advantages__title {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 0 10px;
}

.home-b-advantages__title a {
  text-decoration: none;
  color: #333;
}

.home-b-advantages__title a:hover {
  color: #4caf50;
}

.home-b-advantages__titleb {
  font-size: 36px;
  font-weight: inherit;
  margin-left: 6%;
}

.home-b-advantages__titleb p {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-bottom: -30px;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}

.home-b-advantages__image img {
  max-width: 100%;
  height: auto;
}

.home-b-advantages__info {
  font-size: 1.1rem;
  color: #666;
}

.home-b-advantages:hover {
  transform: scale(1.05);
}

.home-banner-container {
  position: relative;
  width: 85%;
  height: 350px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: -50px;
  margin-bottom: 20px;
  border-radius: 15px;
}

.home-banner-container img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 100px;
  border-radius: 15px;
}

.home-b-advantages__image-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 5%;
  box-sizing: border-box;
}

.home-b-advantages__image {
  text-align: center;
  margin: 10px;
  flex: 1 1 45%;
  max-width: calc(50% - 20px);
}

.home-b-advantages__image p {
  margin-top: -20px;
  font-family: cursive;
  font-weight: bold;
}

.home-video-wrapper {
  position: relative;
  width: 80%;
  padding-top: 56.25%;
}

.home-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  border-radius: 5%;
}

@media (max-width: 768px) {
  .home-b-advantages__image-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .home-b-advantages__image {
    flex: 1 1 100%;
    margin: 10px 0;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }

  .home-video-wrapper {
    width: 100%;
    padding-top: 56.25;
    height: auto;
  }

  .home-video-wrapper iframe {
    width: 80%;
    height: 80%;
  }

  .home-b-advantages__image p {
    margin-top: -20px;
    font-family: cursive;
    font-weight: bold;
  }

  .home-banner-container {
    position: relative;
    width: 100%;
    height: 250px;
    top: 2px;
  }

  .home-banner-container img {
    width: 95%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    display: block;
    margin: 0 auto;
  }
}

.home-container {
  display: flex;
  justify-content: space-evenly;
  padding: 2px;
  border-radius: 8px;
}

.home-image {
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 5px;
  object-fit: contain;
  object-position: top;
  transition: all 0.5s ease;
}

@media (min-width: 768px) {
  .home-image {
    width: 90px;
    height: 90px;
  }
}

@media (min-width: 1024px) {
  .home-image {
    width: 120px;
    height: 120px;
  }
}
.container {
    max-width: 600px;
    margin: auto;
  }
  
  form {
    margin-top: 20px;
  }
  
  .btn-secondary {
    margin-left: 10px;
  }
/* General Section Styling */
.account-section {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding: 20px 0;
  font-family: 'Poppins', sans-serif;
}

/* Breadcrumb Styling */
.account-breadcrumb {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #6c757d;
}

/* Profile Card Styling */
.profile-card {
  margin: 0 auto 20px;
  max-width: 400px;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.profile-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #0a0c54;
  color: #fff;
  font-size: 48px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin: 0 auto 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.circle-text {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #925b27;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  margin-top: 10px;
}

/* User Info Card Styling */
.user-info-card {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.user-info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.user-info-label {
  font-weight: 600;
  flex: 1;
  color: #21072e;
}

.user-info-value {
  flex: 2;
  color: #555;
}

.user-info-input {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Edit and Save Buttons */
.edit-button,
.save-button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: #0c6757;
  color: #fff;
  cursor: pointer;
  margin: 6px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.edit-button:hover,
.save-button:hover {
  background-color: #199e87;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-button:disabled,
.save-button:disabled {
  background-color: #d6d6d6;
  color: #fff;
  cursor: not-allowed;
  box-shadow: none;
}

/* Responsive Styling */
@media (min-width: 992px) {
  .account-container {
    display: flex;
    gap: 20px;
  }

  .profile-column {
    flex: 1;
  }

  .info-column {
    flex: 2;
  }
}
